.step {
  display: flex;
  justify-content: space-between;
  margin: 0 0 50px;
  align-items: center;
  padding: 0px;
  flex-direction: column;

  &.step-reverse {
    flex-direction: column-reverse;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
  .step-img {
    height: 330px;
  }
  &,
  &.step-reverse {
    @media only screen and (min-width: 1025px) {
      flex-direction: row;
      margin: 0 0 60px;
      padding: 0 60px;

      &:last-of-type {
        margin-bottom: 0;
      }

      &:nth-of-type(even) {
        flex-direction: row-reverse;
      }
    }
  }

  button {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}
