.buttons-container {
  display: flex;
  justify-content: space-between;
  column-gap: 30px;
  margin: 30px 0 20px;
  align-items: center;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
		row-gap: 30px;
  }
  img {
    margin-right: 30px;
  }
  a {
    text-decoration: none;
  }
  button {
    min-width: 220px !important;
  }
}

@media only screen and (min-width: 1025px) {
  .buttons-container {
    img {
      margin-right: 10px;
      height: 50px;
			width: 50px;
    }
  }
}
