.blog-page {
	.blog-page-content {
		max-width: 1400px;
		margin: -30px auto 0 ;
		padding: 25px;
		padding: 25px clamp(25px, 3vw, 35px);
	}

	.doubts {
		margin-top: 30px;
	}
	.header-container {
		margin-top: -100px;
		  @media only screen and (max-width: 800px) {
          margin-top: 0;
        }
	}
}
