@import '../../..//theme/variables.scss';

.step-description {
	width: 90%;

	@media only screen and (min-width: 1025px) {
		width: 50%;
	}

	.step-description-subtitle {
		margin-bottom: 10px;
		color: $sun;

		@media only screen and (min-width: 1025px) {
			margin-bottom: 0;
			color: $light-grey;
		}
	}

	.step-description-subtitle,
	& > .title {
		text-align: left;

		@media only screen and (min-width: 1025px) {
			text-align: left;
		}
	}

	h2 {
		margin-top: 0;
		margin-bottom: 20px;
	}
}
