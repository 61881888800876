@import "../../..//theme/variables.scss";

.menu-top-container {
  display: flex;
  nav.menu-top {
    background-color: white;
    padding-top: 5px;
    height: 75px;
    border-bottom: 1.5px white solid;
    transition: border-bottom 0.2s ease;
    &.scrolled {
      border-bottom: 1.5px $sun solid;
    }
    .MuiToolbar-root {
      width: 95%;
      max-width: 1330px;
      margin: 0 auto;
      * {
        flex: 1;
      }
    }
    .logo-wrapper {
      display: flex;
      a {
        max-width: 250px;
      }
      @media only screen and (max-width: 800px) {
        img {
          height: 38px;
          margin-right: 20px;
          margin-top: 5px;
        }
      }
    }
    .hamburger-container {
      display: flex;
      justify-content: flex-end;
      @media only screen and (min-width: 1100px) {
        display: none;
      }
      .hamburger {
        display: flex;
        max-width: 50px;

        img {
          height: 34px;
					width: 34px;
        }
      }
    }

    .nav-links-desktop {
      display: flex;
      column-gap: 30px;
      @media only screen and (max-width: 1100px) {
        display: none;
      }
    }

    .app-btn-wrapper {
      display: flex;
      justify-content: flex-end;
      @media only screen and (max-width: 1100px) {
        display: none;
      }
    }
  }
}
.nav-links-mobile,
.nav-links-desktop {
  a {
    display: flex;
    align-items: center;
    column-gap: 10px;
    text-decoration: none;
    color: black;
    &:hover img {
      filter: grayscale(0);
    }
    img {
      height: 30px;
			width: 30px;
      filter: grayscale(1);
      &.active {
        filter: grayscale(0);
      }
    }
    p {
      min-width: max-content;
      font-size: 16px;
      font-family: PoppinsBold;
    }
  }
}
.app-btn-wrapper button {
  max-width: 200px;
  border-radius: 5px;
  height: 50px;
}
.app-btn-wrapper-mobile {
  button {
    margin: 30px auto;
    border-radius: 5px;
    height: 50px;
  }
}

.nav-links-mobile {
  a {
    height: 65px;
    padding-left: 30px;

    &:nth-child(2) {
      border-top: 1px solid $sun;
      border-bottom: 1px solid $sun;
    }
  }
}
.close-btn-container {
  display: flex;
  justify-content: flex-end;
  .close-btn {
    margin-top: 10px;
    margin-right: 10px;
    img {
      height: 20px;
    }
  }
}
