@import "../../..//theme/variables.scss";

.rules {
  display: flex;
  flex-direction: column;
  align-items: center;
  .rules-wrapper {
    background-color: $grey;
    width: 100%;
  }
  .rules-content {
    margin: 0 100px;
    max-width: 1800px;
    margin: 0 auto;
    padding: 0 20px 50px;
  }
  img {
    display: flex;
    margin: 50px auto;
    width: 60px;
    height: 60px;
  }
  p.title {
    margin-bottom: 20px;
    font-size: 18px;
  }
  p.x-x {
    padding-left: 29px;
    padding-top: 20px;
  }
  p.x-x-x {
    padding-left: 60px;
    padding-bottom: 10px;
    span {
      font-weight: bold;
    }
  }
  a {
    color: #000;
  }
  .title-big {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

@media only screen and (min-width: 1025px) {
  .rules {
    display: flex;
    flex-direction: column;
    align-items: center;
    .rules-content {
      margin: 0 100px;
      max-width: 1440px;
      margin: 0 auto;
      padding: 0 100px 50px;
    }
    img {
      display: flex;
      margin: 50px auto;
    }
    h5 {
      margin-bottom: 20px;
    }
    p.x-x {
      padding-left: 29px;
      padding-top: 20px;
    }
    p.x-x-x {
      padding-left: 60px;
      padding-bottom: 10px;
      span {
        font-weight: bold;
      }
    }
    a {
      color: #000;
    }
  }
}
