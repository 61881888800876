@import "../../..//theme/variables.scss";

.button {
  height: 60px;
  min-width: 260px;
  border: 1px solid $sun;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1px;
  background-color: transparent;
  padding: 0 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.4s;

  &.button-yellow {
    background-color: $sun;
    &:hover {
      background-color: transparent;
    }
  }

  &.button-white {
    background-color: transparent;
    @media (min-width: 800px) {
      &:hover {
        background-color: $sun;
      }
    }
  }

  &.button-grey {
    background-color: lightgray;
    border: none;
  }

  a {
    width: 100%;
    height: 100%;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
  }
}
