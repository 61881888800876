@import "../../..//theme/variables.scss";

.encouragement {
  .encouragement-description {
    text-align: center;
    padding: 50px 0;
    margin: 0 auto;
    max-width: 1025px;
    .inner {
      img {
        height: 350px;
        margin-top: -50px;
				@media only screen and (min-width: 1000px) {
          height: 450px;
        }
        @media only screen and (min-width: 1825px) {
          height: 550px;
        }
      }
      .buttons-wrapper {
        display: flex;
        justify-content: center;
				flex-wrap: wrap;
        column-gap: 80px;
				row-gap: 30px;
        margin-bottom: 50px;
      }
    }
  }
  @media only screen and (min-width: 1025px) {
    .encouragement-description {
      padding: 60px 0px 40px;
    }
  }
}
