.image {
	display: block;
	width: 100%;
	height: auto;
	object-fit: contain;
	margin: 0;
	padding: 0;

	&.step-img {
		width: 90%;
		&:nth-child(1) {
			margin: 50px 0;
		}

		@media only screen and (min-width: 1025px) {
			width: 35vw;
			max-width: 500px;
		}
	}
}
