.main-banner {
	position: absolute;
	top: calc(100vh - 75px);
	left: 0;
	right: 0;
	z-index: 100;
	display: flex;
	justify-content: center;
	opacity: 0;
	pointer-events: none;
	transition: opacity 0.3s;
	will-change: transform;

	&.main-banner-shown {
		opacity: 1;
		pointer-events: all;

		.main-banner-inner {
			transform: scaleY(1);
		}
	}

	.main-banner-inner {
		transform: scaleY(0);
		box-shadow: 0 18px 50px -10px rgb(0 0 0 / 20%);
		overflow: auto;
		transition: transform 0.4s;
	}

	img {
		display: block;
		margin: 0 auto;
		height: 65px;
		width: 100%;
	}
}
