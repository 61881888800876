.page-loader {
	.page-loader-inner {
		$loader-size: 130px;
		position: absolute;
		left: 50%;
		top: 50%;
		width: $loader-size;
		height: $loader-size;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		font-size: 0;
		outline: none !important;
		transform: translate(-50%, -50%);

		img {
			display: block;

			&:first-of-type {
				width: 60%;
				height: 60%;
				animation: spin 20000ms infinite linear;
			}

			&:last-of-type {
				position: absolute;
				height: 100%;
				width: 100%;
				animation: spin 15000ms reverse infinite linear;
			}
		}
	}
}