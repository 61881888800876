@import '../../..//theme/variables.scss';

.contact {
	h1 {
		text-align: center;
		width: 90%;
		max-width: 800px;
		margin: 50px auto 80px;
	}
}
