@import "../../..//theme/variables.scss";

.footer {
  padding: 40px 0 15px;
  border-top: 1px solid $sun;

  .footer-content {
    width: 80%;
    max-width: 1300px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 30px;
    @media (min-width: 800px) {
      width: 90%;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
    }
    .sunmetric-logo {
      height: 90px;
			width: 85px;
      @media (max-width: 800px) {
        display: none;
      }
    }
    .contact-container,
    .address-container {
			align-self: baseline;
      @media (max-width: 800px) {
        width: 90%;
        display: flex;
				flex-direction: column;
        justify-content: space-between;
      }
      a {
        color: black;
        font-size: inherit;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
      p {
        font-size: 15px;
        margin-bottom: 10px;
      }
      .sm-links {
        padding-top: 10px;
        margin-left: -3px;
       
        img,
        svg {
          margin-right: 10px;
          height: 30px;
          @media (max-width: 800px) {
            margin-right: 0;
          }
          #yellow {
            transition: fill 0.4s;
          }
          @media (min-width: 800px) {
            &:hover #yellow {
              fill: $sun;
            }
          }
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
    .links-container {
			margin-top: -10px;
			margin-bottom: -10px;
      p {
        font-size: 16px;
      }
      a {
        font-size: 16px;
      }
      &.mobile {
        display: none;
        @media (max-width: 800px) {
          display: flex;
          align-items: baseline;
          justify-content: space-between;
          width: 100%;
        }
      }
      &.desktop {
        display: none;
        @media (min-width: 801px) {
          display: block;
        }
      }
    }
    .investors-logos {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
			img {
				width: 100%;
				height: 100%;
			}
      @media (max-width: 800px) {
        flex-direction: row;
        column-gap: 20px;
      }
    }
    .title-small {
      margin: 0;
      font-size: 18px;
      @media (min-width: 801px) {
        display: none;
      }
    }
  }

  .footer-banner {
    .footer-investor-img {
      margin-top: 40px;
      padding-top: 40px;
      border-top: 1px solid $sun;

      @media (min-width: 800px) {
        display: none;
      }

      img {
        display: block;
        margin: 0 auto;
      }
    }

    .footer-banner-img {
      width: 100%;
      overflow: auto;

      img {
        display: block;
        margin: 0 auto;
				width: fit-content;
				height: 100%;
      }
    }

    .footer-banner-text {
      display: block;
      max-width: 1200px;
      width: 85%;
      text-align: center;
      font-size: 16px;
      margin: 30px auto 30px;
    }

    .copyright {
      max-width: 1180px;
      width: 85%;
      margin: 30px auto 0;
      display: flex;
      justify-content: space-between;
      @media (max-width: 800px) {
        display: none;
      }
      a,
      p {
        font-size: 16px;
        margin: 0;
      }
      .other-links {
        display: flex;
        column-gap: 50px;
      }
    }
    .footer-logo {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 20px;
      border-top: 1px solid $sun;
      padding-top: 15px;
      @media (min-width: 801px) {
        display: none;
      }
      img {
        height: 35px;
				width: auto;
      }
      p {
        font-family: PoppinsBold;
      }
    }
  }
}
