@import '../../..//theme/variables.scss';

.title-big {
	font-size: 28px;
	margin-top: 0px;
	width: 90%;
	margin: 0 auto;
	text-align: center;
	font-weight: 700;

	span {
		font-size: 28px;
	}

	span {
		background-size: 100% 15%;
		background-repeat: repeat-x;
		background-position: left 0% bottom 10%;
		background-image: linear-gradient(181deg, #fcc200 50%, transparent 54%, transparent 100%);
		font-weight: 400;
	}
}

@media only screen and (min-width: 1025px) {
	.title-big {
		font-size: 38px;
		margin-top: 100px;

		span {
			font-size: 42px;
		}
	}
}

