.header {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 0 20px 50px;
  text-align: center;
  @media only screen and (max-width: 800px) {
    padding-bottom: 0px;
  }
  &.header-banner {
    svg {
      max-width: 100%;
      margin: -100px auto 0;
      height: 100%;
      min-height: 350px;
      @media only screen and (max-width: 768px) {
        margin: -20px auto 0;
      }
    }
  }

  @media only screen and (min-width: 1025px) {
    min-height: 500px;
    padding-bottom: 0;
  }

  .title-big {
    min-width: 300px;
    margin: 0 auto;

    @media only screen and (min-width: 1025px) {
      font-size: 40px;
      line-height: 1.2em;
    }
    @media only screen and (min-width: 1826px) {
      font-size: 50px;
    }

    span {
      font-size: inherit;
    }
  }

  p.cursive {
    max-width: 669px;
    margin: 50px auto 0;
    font-size: 21px;
    font-size: clamp(21px, 4vw, 40px);
    text-align: center;
  }

  button {
    margin: 60px auto 0;
    max-width: 80%;
  }

  .image-container {
    display: grid;
    justify-items: center;
    align-items: center;
    height: 900px;
    margin-top: -50px;
    @media only screen and (max-width: 800px) {
      height: fit-content;
      margin-top: 0;
    }
    img {
      grid-column: 1;
      grid-row: 1;
      max-width: 100%;
      &:first-child {
        width: 900px;
        height: 900px;
        @media only screen and (max-width: 800px) {
          height: auto;
        }
      }
      &:nth-child(2) {
        animation: spin2 80s linear infinite;
        width: 600px;
        height: 600px;
        @media only screen and (max-width: 800px) {
          height: auto;
        }
      }
      &:nth-child(3) {
        width: 807px;
        height: 371px;
        scale: 0.8;
        z-index: 1;
        @media only screen and (max-width: 800px) {
          height: auto;
        }
      }
    }
  }
}

@keyframes spin2 {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0deg);
  }
}
