@import "../../..//theme/variables.scss";

.pricings-info {
  margin-bottom: 60px;
	padding: 60px 0 50px;

  .pricing-info-frame-container {
    max-width: 1000px;
    margin: 0 auto 20px;

    .frame {
      margin-bottom: 30px;
    }
		h2 {
			text-align: center;
		}
  }

  .frames-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;
    column-gap: clamp(30px, 8vw, 120px);
		  @media only screen and (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }

  .pricings-info-frame {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: 100%;
    img {
      height: 60px;
      margin: 50px;
    }
  }

  .price {
    font-size: 30px;
    line-height: 1.3em;
    font-family: PoppinsBold;
  }
}
