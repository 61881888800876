@import "../../..//theme/variables.scss";

.why-sunmetric {
  &.container-full-width {
    background-color: #fff;
  }
  .sunmetric-reasons {
    text-align: center;
    padding: 50px 0;
    margin: 0 auto;
    max-width: 1025px;
    .inner {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
      grid-gap: 1rem;
      .item-container {
        margin: 20px;
        img {
          height: 60px;
          margin-bottom: 20px;
          @media only screen and (min-width: 1700px) {
            height: 75px;
          }
        }
        .title-small {
          font-size: 18px;
          font-weight: bold;
        }
      }
    }
    button {
      margin: 70px auto 20px;
      max-width: 90%;
    }
  }
  @media only screen and (min-width: 1025px) {
    &.container-full-width {
      background-color: $grey;
    }

    .sunmetric-reasons {
      padding: 60px 20px 40px;
    }
  }
}
