@import "../../..//theme/variables.scss";

.privacy {
  display: flex;
  flex-direction: column;
  .rules-wrapper {
    background-color: $grey;
    width: 100%;
  }
  .rules-content {
    margin: 0 100px;
    max-width: 1800px;
    margin: 0 auto;
    padding: 50px 20px 50px;
  }
  a {
    color: #000;
  }
  p {
    &:nth-child(2) {
      margin-top: 50px;
    }
    span {
      font-weight: bold;
    }
  }

  img {
    display: flex;
    margin: 50px auto;
    width: 60px;
    height: 60px;
  }

   p.title {
    margin-bottom: 20px;
		margin-top: 50px;
    font-size: 18px;
  }
  .title-big {
    margin-top: 0;
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 1025px) {
  .privacy {
    display: flex;
    flex-direction: column;
    .rules-content {
      margin: 0 100px;
      max-width: 1440px;
      margin: 0 auto;
      padding: 50px 100px 50px;
    }
    a {
      color: #000;
    }
    p {
      &:nth-child(2) {
        margin-top: 50px;
      }
      span {
        font-weight: bold;
      }
    }

    img {
      display: flex;
      margin: 50px auto;
    }

    h5 {
      margin-top: 25px;
      margin-bottom: 20px;
    }
  }
}
