@import "../../..//theme/variables.scss";

.scroll-top-button {
  --button-size: 40px;
  position: fixed;
  bottom: 60px;
  right: 20px;
  right: clamp(20px, 5vw, 50px);
  width: var(--button-size);
  height: var(--button-size);
  background-color: white;
  border: none;
  z-index: 100;
  cursor: pointer;
  outline: none;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.4s;
  border-radius: 50%;
	border: 1.5px solid $sun;
	padding: 9px;

  &.is-shown {
    opacity: 1;
    pointer-events: all;
  }

  @media (min-width: 1650px) {
    right: 50%;
    transform: translateX(800px);
  }

  &:hover {
    .scroll-top-button-border {
      transform: rotate(40deg);
    }
  }

  .scroll-top-button-border {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: transform 0.4s;
    pointer-events: none;
  }

  .scroll-top-button-arrow {
    width: 100%;
		height: 100%;
  }
}
