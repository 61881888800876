.title {
  margin-bottom: 50px;
  font-size: 24px;

  &.title-small {
    margin-bottom: 0.5em;
    font-size: 22px;
    font-size: clamp(22px, 2vw, 26px);
  }

  &.title-large {
    font-size: 35px;
		 @media only screen and (max-width: 1000px) {
      font-size: 30px;
    }
    @media only screen and (min-width: 1825px) {
      font-size: 40px;
    }
  }
}

@media only screen and (min-width: 1025px) {
  .title-medium {
    font-size: 28px;
  }
}

@media only screen and (max-width: 800px) {
  .title-medium {
    text-align: left;
  }
}