@import '../../..//theme/variables.scss';

.blog-list {
	.title-medium {
		margin-bottom: 14px;
		text-align: center;
	}

	.blog-list-category {
		margin: 40px 0;
		margin: clamp(40px, 7vw, 70px) 0;

		.title {
			text-align: center;
		}
	}

	.blog-list-content {
		list-style: none;

		@media (min-width: 720px) {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			row-gap: 20px;
			column-gap: 20px;
			row-gap: clamp(20px, 6vw, 60px);
			column-gap: clamp(20px, 6vw, 70px);
		}

		@media (min-width: 980px) {
			grid-template-columns: repeat(3, 1fr);
		}
	}

	.pagination-wrapper {
		display: flex;
		justify-content: center;
		margin-top: 30px;

		.Mui-selected {
			background-color: $sun;
		}
	}
}
