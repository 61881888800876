@import '../../..//theme/variables.scss';

.blog-card {
	margin: 30px 0;

	@media (min-width: 720px) {
		margin: 0;
	}

	.text,
	.blog-card-tag,
	.blog-card-link {
		font-size: 18px;
	}

	.blog-card-tag {
		display: inline-block;
		padding-right: 7px;
		margin: 10px 0;
		text-decoration: none;
		color: $dark-grey;

		&:hover {
			cursor: pointer;
			color: $sun;
		}
	}

	.image {
		height: 250px;
		margin: 10px 0;
		object-fit: cover;
	}

	.blog-card-link {
		text-decoration: none;
		color: black;

		.title {
			text-align: left !important;
			line-height: 1.3;
		}
	}

	.text {
		width: auto;
		text-overflow: ellipsis;
		overflow: hidden;
		display: -webkit-box !important;
		-webkit-line-clamp: 5;
		-webkit-box-orient: vertical;
		white-space: normal;
	}
}
