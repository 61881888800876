@import "../../..//theme/variables.scss";

.blog-post-page {
  margin: 0 15px;

  .blog-post-page-content {
    @media (max-width: 768px) {
      margin-top: 30px;
    }

    margin-top: 70px;
  }

  .blog-post-heading {
    display: grid;
    grid-template-columns: 0.9fr 1.1fr;
    column-gap: 50px;
    row-gap: 30px;
    margin-bottom: 100px;
    img {
      max-width: 100%;
      width: fit-content;
      height: 100%;
    }
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
      margin-bottom: 50px;
    }

    .blog-post-title {
      h1 {
        margin: 0;
        text-align: left;
      }

      .text {
        width: 75%;

        @media (max-width: 768px) {
          width: 100%;
          margin-top: 20px;
        }
      }
    }
  }

  .blog-post-content {
    margin-bottom: 100px;

    h3 {
      font-size: 30px;
      margin-bottom: 30px;
      margin-top: 30px;
    }

    a {
      color: $sun;
    }

    img {
      max-width: 100%;
      width: fit-content;
      height: 100%;
    }

    @media (max-width: 768px) {
      margin-bottom: 70px;
    }
  }

  .blog-post-buttons {
    margin-left: 25px;
    margin-right: 25px;

    a {
      text-decoration: none;
      color: black;
    }

    .blog-post-frame-container {
      margin-bottom: 80px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      row-gap: 50px;

      .col {
        width: 280px;
      }
    }

    .cta-frame-container {
      position: relative;

      .cta-frame {
        width: 280px;
        border: 1px solid $sun;
        display: flex;
        justify-content: center;
        column-gap: 20px;
        box-sizing: border-box;
        position: relative;
        background-color: #fff;
        align-items: center;
        padding: 10px;
      }

      .cta-frame-yellow {
        position: absolute;
        background-color: $sun;
        width: 280px;
        height: 80px;
        top: 10px;
        left: -25px;
        z-index: 0;
        transform: rotate(-5deg);
      }

      .cta-frame-yellow-reverse {
        @extend .cta-frame-yellow;
        transform: rotate(5deg);
        top: 10px;
        left: 25px;
      }

      .arrow-reverse {
        transform: scale(-1);
      }
    }

    button {
      height: 82px;
    }
  }
}
