.page-not-found-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;

  .img {
    position: relative;
    max-width: 500px;
    img {
      max-width: 90%;
    }
  }

  button {
    margin: 40px 0;
    width: 300px;
  }
}
