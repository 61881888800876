@import "./theme/variables.scss";

@font-face {
  font-family: Poppins;
  src: url(./assets/fonts/Poppins/Poppins-Regular.ttf);
  font-display: swap;
}

@font-face {
  font-family: PoppinsBold;
  src: url(./assets/fonts/Poppins/Poppins-SemiBold.ttf);
  font-display: swap;
}

@font-face {
  font-family: Kalam;
  src: url(./assets/fonts/Kalam/Kalam-Light.ttf);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Poppins;
  font-size: 18px;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.container {
  max-width: 1440px;
  margin: 0 auto;
}

.container-full-width {
  width: 100%;
  margin: 0;
  background-color: $grey;
  margin: 0 auto;
}

.title-big {
  font-family: PoppinsBold;
  span {
    font-family: Poppins;
  }
}

.title {
  font-family: PoppinsBold;
}

h5 {
  font-family: PoppinsBold;
}

.cursive {
  font-family: Kalam;
  color: $sun;
  font-size: 38px;
}

@keyframes spin {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0deg);
  }
}

.nav-mobile-paper {
.MuiDrawer-paperAnchorTop {
	width: 100%!important;
}
}

 #spinning {
    animation: spin 70000ms infinite linear;
    transform-origin: center;
  }