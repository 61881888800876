@import "../../..//theme/variables.scss";

.blog-container-full-width {
  margin-top: -70px;

  @media only screen and (min-width: 1025px) {
    background-color: $grey;
  }
  .blog-header {
    max-width: 1440px;
    margin: 30px auto;

    padding: 80px 0;

    @media (min-width: 800px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 20px;
      column-gap: clamp(20px, 6vw, 60px);
    }

    @media (min-width: 1200px) {
      grid-template-columns: 1.3fr 1fr;
      column-gap: clamp(50px, 3vw, 140px);
    }

    @media (max-width: 768px) {
      padding: 0 30px;
    }

    .image {
      margin-bottom: 20px;
      max-height: 550px;
      height: 100%;
      object-fit: cover;
      object-position: center center;

      @media (min-width: 800px) {
        margin-bottom: 0;
      }
    }

    .blog-header-content {
      display: flex;
      flex-direction: column;
      height: 100%;

      .text {
        flex-grow: 1;
        margin-bottom: 26px;
      }
      .button-yellow {
        width: 420px;
        margin: 0 auto;
        @media (max-width: 768px) {
          width: 320px;
        }
      }
    }
  }
}
