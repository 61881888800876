@import "../../../index.scss";

.contact-form-wrapper {
  padding: 50px 0;
  .frame {
    max-width: 900px;
    height: fit-content;
    z-index: 5;
    margin: 0 auto;
  }
  .title-large {
    text-align: center;
  }
  .contact-form {
    display: flex;
    flex-direction: column;
    padding: 0;
    .inner {
      display: flex;
      column-gap: 50px;
      @media only screen and (max-width: 800px) {
        flex-direction: column;
      }
      .col1,
      .col2 {
        flex: 1;
      }
      .MuiFormControl-root {
        width: 100%;
      }
      textarea {
        height: 200px;
      }
    }
    label {
      color: #9e9e9e;
      font-size: 14px;
    }

    .MuiFormGroup-root {
      margin-top: 20px;
    }

    .MuiButtonBase-root {
      color: $sun;

      &.error {
        color: #d25353;
      }
    }

    .MuiInputBase-root fieldset {
      border-color: $sun;
    }

    .MuiFormControlLabel-label {
      color: #9e9e9e;
      font-size: 14px;

      a {
        color: $sun;
        font-size: 14px;
      }
    }

    .errorMsg {
      display: block;
      font-size: 13px;
      color: #d25353;
    }

    button {
      margin: 20px auto 0;
      width: 200px;
    }
    .message-status {
      position: absolute;
      bottom: 18px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 16px;
      font-family: PoppinsBold;
    }
    .MuiAlert-root {
      width: 90%;
      margin: 20px auto -40px;
      display: flex;
      justify-content: center;
      .MuiAlert-message {
        font-size: 15px;
      }
    }
  }
}
