.logo {
	display: block;
	height: 40px;
width: 240px;
	&.logo-desktop {
		display: none;
	}

	&.logo-mobile {
		height: 55px;
	}

	@media (min-width: 501px) {
		&.logo-desktop {
			display: block;
		}

		&.logo-mobile {
			display: none;
		}
	}
}
