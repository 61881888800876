@import '../../..//theme/variables.scss';

.frame {
	width: 100%;
	padding: 20px 0;
	overflow: hidden;

	@media (min-width: 769px) {
		overflow: initial;
	}

	.frame-content {
		position: relative;
		height: 100%;
		background-color: #fff;
		z-index: 1;
		padding: 50px 20px;
		padding: clamp(50px, 6vw, 60px) clamp(20px, 4vw, 40px);
	}

	.frame-inner {
		position: relative;
		background-color: #fff;
		border: 1px solid $sun;
		width: calc(100% - 5px);
		margin-left: auto;
		height: 100%;

		&::before {
			content: '';
			background-color: $sun;
			position: absolute;
			width: calc(100% + 7px);
			height: 100%;
			transform: skewY(-3deg);
			top: 0;
			left: -6px;

			@media (min-width: 769px) {
				top: 10px;
				width: 100%;
				left: -25px;
				transform: rotate(-5deg);
			}
		}
	}

	&.frame-reverse {
		.frame-inner {
			margin-left: 0;

			&::before {
				left: auto;
				right: -6px;
				transform: skewY(3deg);

				@media (min-width: 769px) {
					right: -25px;
					transform: rotate(5deg);
				}
			}
		}
	}

	&.frame-borderless {
		.frame-inner {
			&::before {
				display: none;
			}
		}
	}
}
