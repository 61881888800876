.header-container {
  max-width: 1300px;
  margin-top: -80px;
  @media only screen and (max-width: 1025px) {
    margin-top: 30px;
  }
  .default-header {
    display: flex;
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: 1025px) {
      flex-direction: column;
    }
    @media only screen and (min-width: 768px) and (max-width: 1825px) {
      height: 700px;
    }
    .title {
      max-width: 600px;
      margin-bottom: 0;
      @media only screen and (min-width: 1025px) {
        position: relative;
        left: 90px;
				.title-big {
					font-size: 45px;
				}
      }
    }
    h1 {
      margin: 0 auto;
    }
    .header-img-container {
      display: flex;
      justify-content: center;
      img {
        width: 900px;
        height: 100%;
        max-width: 100%;
        @media only screen and (max-width: 1025px) {
          height: 600px;
          margin-top: -30px;
        }
        @media only screen and (max-width: 600px) {
          height: 400px;
        }
        @media only screen and (max-width: 800px) {
          margin-bottom: 0;
        }
      }
    }
  }
}
