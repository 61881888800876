@import "../../..//theme/variables.scss";

.lang-select {
  margin-top: 7px !important;
  max-width: 50px;
	 @media only screen and (max-width: 800px) {
       margin-top: 0;
      }
  .MuiSelect-select {
    padding: 5px;
  }

  p {
    font-weight: bolder;
    font-size: 15px;
  }

  .MuiSvgIcon-root {
    fill: $sun;
  }

  .Mui-focused,
  &:hover {
    & > fieldset {
      border: none !important;
    }
  }

  fieldset {
    border: none !important;
  }
}

.lang-select-menu {
  &.Mui-selected {
    background-color: white !important;
  }

  p {
    margin: 0 10px;
  }
}
