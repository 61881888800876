@import "../../..//theme/variables.scss";

.report-info-container {
  .report-info {
    padding: 50px 10px 0;
    margin: 0 auto;
    max-width: 1225px;
    @media only screen and (max-width: 800px) {
      padding-top: 0px;
    }
    h2.title-medium {
      text-align: center;
    }
    .report-info-section-container {
      margin-bottom: 70px;
      .report-info-section {
        display: grid;
        grid-auto-columns: 1fr;
        grid-auto-flow: column;
        column-gap: 70px;
        row-gap: 30px;
        margin-bottom: 50px;

        @media only screen and (max-width: 768px) {
          display: flex;
          flex-direction: column;
          padding: 0 20px;
          &.revers {
            flex-direction: column-reverse;
          }
        }

        .info-section-wrappper {
          margin-bottom: 20px;
          .title {
            display: flex;
            align-items: center;
            column-gap: 15px;
            margin-bottom: 5px;
            p {
              font-size: 15px;
              font-family: PoppinsBold;
            }
            img {
              width: 25px;
              height: 100%;
            }
          }
          .text {
            padding-left: 40px;
            font-size: 14px;
          }
        }
        .info-section-wrappper-simple {
          .title {
            display: flex;
            align-items: center;
            column-gap: 15px;
            margin-bottom: 15px;
            p {
              font-size: 15px;
            }
            img {
              width: 35px;
              height: 100%;
            }
          }
          .text {
            font-size: 14px;
          }
        }
        .img-wrapper {
          &.map {
            height: 300px;
          }
          &.road {
            height: 210px;
          }
          &.electricity {
            height: 360px;
            margin-top: -40px;
          }
          &.kw {
            height: 230px;
            margin-top: -50px;
          }
          &.areaMap {
            height: 170px;
          }
          img {
            height: 100%;
          }
        }
      }
    }
    button {
      margin: 30px auto;
    }
  }
}
