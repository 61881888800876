@import "../../..//theme/variables.scss";

.contact-info {
  padding: 50px 0;
  margin: 0 auto;
  max-width: 1025px;
  .title-large {
    text-align: center;
  }
  .inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    row-gap: 40px;
    @media only screen and (max-width: 1025px) {
      flex-direction: column;
    }
    .contact-data {
      display: flex;
      flex-direction: column;
      row-gap: 60px;
      .contact-data-item {
        display: flex;
        align-items: center;
        column-gap: 30px;

        img {
          height: 60px;
					width: 60px;
        }
        p {
          font-family: PoppinsBold;
          font-size: 26px;
        }
      }
    }
    .main-img {
      width: 330px;
			height: 100%;
    }
  }
}
