.home-container {
  overflow-x: hidden;
  position: relative;
  padding-top: 40px;
  .single-quote-container {
    display: none;

    @media (min-width: 600px) {
      display: block;
    }
  }
}
