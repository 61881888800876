.fetch-error {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 40px 20px 70px;
	text-align: center;

	img {
		display: block;
		width: 100%;
		max-width: 400px;
	}

	h1 {
		margin: 40px auto;
		font-size: 28px;
		font-weight: 400;
		line-height: 1.3em;
	}
}
