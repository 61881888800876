@import "../../..//theme/variables.scss";

.what-is-sunmteric {
  &.container-full-width {
    background-color: #fff;
  }

  .sunmteric-description {
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 50px 0;
    margin: 0 auto;
    max-width: 1125px;

    .inner {
      padding: 0 20px;
      img {
        margin: 50px;
        height: 80px;
				width: 80px;
      }
      p {
        text-align: left;
        font-size: 18px;
        @media only screen and (min-width: 1025px) {
          text-align: center;
        }
      }

      .columns-container {
        display: flex;
        flex-direction: column;
        column-gap: 65px;
        row-gap: 50px;
        @media only screen and (min-width: 1025px) {
          flex-direction: row;
        }
        p {
          text-align: left;
					flex: 1 1 0;
        }
      }
    }
  }

  @media only screen and (min-width: 1025px) {
    &.container-full-width {
      background-color: $grey;
    }
  }
}
